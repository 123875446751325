import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { BASE_V2_URL } from '../../../api'
import _ from 'lodash'
import { Container } from '../styledComponents'
import { connect } from 'react-redux'
import Table from '../../Table'
import styled, { css } from 'styled-components'
import { cssvar } from '../../../styles/var'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { languageToTranslatedDescriptionLanguage } from '../Injury/DetailedInfo'
import PlayerCell from '../../Table/PlayerCell'
import moment from 'moment'
import WorldIcon from '../../../images/World.svg'
import { PLAYER_ND_IMAGE_URL } from '../../../helpers'

const OngoingCompetitionInjuries = ({ data }) => {
  const { t, i18n } = useTranslation()

  const [competition, setCompetition] = useState()

  const history = useHistory()

  const fetchData = useCallback(
    _.debounce(() => {
      async function fetch() {
        try {
          const competitionResult = await axios(
            `${BASE_V2_URL}/competitions/${data.tag}?timeframe=current_season`,
          )
          setCompetition(competitionResult.data)
        } catch (err) {
          console.log('Unable to load data for', data.tag)
        }
      }
      fetch()
    }, 300), [],
  )

  useEffect(() => {
    fetchData()
  }, [])

  const goToPlayer = (row) => {
    history.push(`/explore/${row.player.tag}`)
  }

  const competitionTeamsTags = useMemo(() => (competition ? Object.keys(competition.teams) : null), [competition])

  const ongoingInjuries = useMemo(() => {
    if (!competition || !competitionTeamsTags) return undefined

    const injuredPlayers = Object.values(competition.players)
      .filter(player => competitionTeamsTags.includes(player.player.profile_data.meta.team_tag)) // Only show teams that are currently in the competition
      .filter(player => !!player.current_status.ongoing_injuries && player.current_status.ongoing_injuries.length > 0) // Filter for players with an ongoing injury
      .filter((player, index, arr) => arr.findIndex(p => p.player.id === player.player.id) === index) // Remove duplicates (Players that moved during the season)


    return injuredPlayers.flatMap(player => player.current_status.ongoing_injuries.map((injury) => {
      const currentLang = i18n.languages[0].toLowerCase()
      const targetLang = currentLang === 'pt-pt'
        || currentLang === 'fr-fr'
        || currentLang.includes('en') ? 'en-gb' : currentLang

      const injuryDescription = injury.translated_description
        ? injury.translated_description[languageToTranslatedDescriptionLanguage[targetLang]]
        : injury.description
          ? injury.description
          : undefined

      const team = competition.teams[player.player.profile_data.meta.team_tag]

      const common_name = player.player.profile_data.meta.common_name

      return {
        player: {
          image: player.player.profile_data.image,
          sortable_name: _.includes(common_name, '. ') ? _.split(common_name, '. ')[1] : _.trim(common_name),
          common_name: player.player.profile_data.meta.common_name,
          tag: player.player.tag,
          role: player.player.profile_data.meta.role,
        },
        team: team ? { name: team.team.meta.common_name, image: player.player.profile_data.meta.team_image } : { name: '-', image: undefined },
        date: injury.date,
        description: injuryDescription,
      }
    }))
  }, [competition, i18n.languages])


  const columns = [
    {
      title: t('Player'),
      dataIndex: ['player', 'sortable_name'],
      sortable: true,
      render: (val, row) => (
        <PlayerCell
          image={row.player.image}
          name={row.player.common_name}
          onError={(e) => {
            e.target.src = PLAYER_ND_IMAGE_URL
          }}
          extra={(
            <PlayerCell
              image={row.team.image}
              name={row.team.name}
              smallImage
              onError={(e) => {
                e.target.src = WorldIcon
              }} />
          )}
          extraBreakpoint="max-width:640px" />
      ),
    },
    {
      title: t('team-singular'),
      dataIndex: ['team', 'name'],
      sortable: true,
      align: 'left',
      hide: true,
      hideOnBreakpoint: 'max-width: 640px',
    },
    {
      title: t('role'),
      dataIndex: ['player', 'role'],
      sortable: true,
      align: 'left',
      render: role => t(role),
    },
    {
      title: t('injury-injured'),
      dataIndex: ['date'],
      sortable: true,
      align: 'left',
      render: val => moment(val).format('DD-MM-YYYY'),
    },
    {
      title: t('injury-description'),
      dataIndex: ['description'],
      sortable: false,
      align: 'left',
    },
  ]

  return (
    <Container>
      <Widgets>
        {!!ongoingInjuries && (
          <Widget fullWidth>
            <WidgetTitle>
              {t('Ongoing injuries')}
              <span>{ongoingInjuries.length}</span>
            </WidgetTitle>
            <Table
              columns={columns}
              data={ongoingInjuries}
              defaultSorting={['date']}
              defaultSortingOrder='desc'
              inverseSortingOrderCycle
              // defaultSorting={['stats', 'days_missed_due_to_injuries']}
              hideColumnsBreakpoint='max-width: 640px'
              onRowClick={goToPlayer}
            />
          </Widget>
        )}
      </Widgets>
    </Container>
  )
}


function mapStateToProps({ filters, navDrilldownHeader }) {
  return { filters, navDrilldownHeader }
}

export default connect(mapStateToProps)(OngoingCompetitionInjuries)

const Widgets = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`
const Widget = styled.div`
  ${props => props.fullWidth
    && css`
      @media (min-width: 1025px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 15px;
  background-color: #fff;
  .recharts-surface {
    margin-top: -4px;
  }
`
const WidgetTitle = styled.h4`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-top: -2px;
    padding: 3px 8px;
    border: 2px solid ${cssvar('injuryColor')};
    border-radius: 100px;
    color: ${cssvar('injuryColor')};
    font-size: 13px;
    font-weight: 650;
    line-height: 1;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    span {
      margin-left: 5px;
      font-size: 11px;
    }
  }
`
export const PlayerAvatar = styled.img`
  height: 24px;

  margin-right: 10px;
`